import React, { Component } from 'react';
import { Browser } from '@capacitor/browser';
import ReactGA from 'react-ga4';


class InterestingBanners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonDataSet: [],
      filteredjsonDataSet: [],
      filteredjsonDataSet2: [],
      isLoading: true
    };
  }

  componentDidMount() {
    fetch("https://link-router.netlify.app/damkoto/data/InterestingBanners.json")
      .then(response => response.json())
      .then(responsejsonDataSet => {
        this.setState({ jsonDataSet: responsejsonDataSet });
        this.setState({ isLoading: false });
      });
  }

  render() {
   // open openInAppBrowser
const openInAppBrowser = async (externalUrl,gacategory, gaaction) => {
  await Browser.open({
      url: externalUrl,
      windowName: 'Dam koto App',
      toolbarColor: '#ffffff',
  });
  ReactGA.event({
    category: gacategory,
    action: gaaction
  });
};
// open openInAppBrowser

const sortedData = this.state.jsonDataSet.sort((a, b) => parseInt(b.key_id) - parseInt(a.key_id));
    return (
      <>

        <div className="service-container overflow-x-auto flex gap-2 py-1 text-left">
          {sortedData.map(individualjsonData => {
            return (
              
                <img src={individualjsonData.app_icon} key={individualjsonData.key_id} alt="" className='rounded-md shadow-md w-3/5' onClick={() => openInAppBrowser(individualjsonData.app_link,"Clicked top Banner",individualjsonData.app_name)} />
              
            );
          })}
        </div>
      </>
    );
  }
}

export default InterestingBanners;