import React, { Component } from 'react';
import ReactGA from 'react-ga4';

class InterestingApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonDataSet: [],
            filteredjsonDataSet: [],
            filteredjsonDataSet2: [],
            isLoading: true,
            isDataLoaded: false,
        };
    }

    componentDidMount() {
        fetch("https://link-router.netlify.app/damkoto/data/InterestingApps.json")
            .then(response => response.json())
            .then(responsejsonDataSet => {
                this.setState({ jsonDataSet: responsejsonDataSet });
                this.setState({ isLoading: false });
                this.setState({ isDataLoaded: true });
            });
    }

    render() {
        // open openInAppBrowser
        const openInAppBrowser = async (externalUrl,gacategory, gaaction) => {
            await window.open(externalUrl, '_blank');
            ReactGA.event({
                category: gacategory,
                action: gaaction
              });
        };
        // open openInAppBrowser
        const sortedData = this.state.jsonDataSet.sort((a, b) => parseInt(b.key_id) - parseInt(a.key_id));

        return (
            <>
            {this.state.isDataLoaded ?
                <div className='service-group rounded-lg bg-white dark:bg-slate-800 mt-3'>
                    <div className="section-title bg-white dark:bg-slate-800 rounded-t-lg p-2 pl-5 text-gray-800 dark:text-white text-lg text-center shadow-sm">Interesting Apps</div>
                    <div className="service-container overflow-x-auto flex gap-2 text-left">

                        {sortedData.map(individualjsonData => {
                            return (

                                <div onClick={() => openInAppBrowser(individualjsonData.app_link,"Clicked Interesting Apps",individualjsonData.app_name)} className="service-item flex-none py-5 px-2 w-2/5" key={individualjsonData.key_id}>
                                    <div className="service-item-img flex first:pl-1 items-center">
                                        <img src={individualjsonData.app_icon} width={"40px"} alt={individualjsonData.app_name} className="inline rounded-lg border border-gray-400" />
                                        <p className='text-sm ml-2 dark:text-white'>{individualjsonData.app_name}</p>
                                    </div>
                                </div>

                            );
                        })}

                    </div>
                </div>:null}
            </>
        );
    }
}

export default InterestingApps;