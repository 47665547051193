import React from 'react';
import ReactGA from 'react-ga4';


import InterestingBanners from '../components/InterestingBanners';
import InterestingApps from '../components/InterestingApps';
import HorizontalBanners from '../components/HorizontalBanners';
import ShopGroupImporter from '../components/ShopGroupImporter';
import Actionbar from '../components/Actionbar';
import PushNotification from '../components/PushNotification';

ReactGA.initialize('G-6FBVTPR7TP');
ReactGA.event({
    category: 'browsing',
    action: 'Visited Home Screen'
});


const Home = () => {
    return (<>
        <Actionbar />

        <div className="container mx-auto p-1">

            <InterestingBanners />
            <ShopGroupImporter/>
            <InterestingApps />
            <HorizontalBanners />
            <PushNotification />

        </div>

    </>);
}

export default Home;