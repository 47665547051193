import React, { Component } from 'react';
import ShopsGroup from '../components/ShopsGroup';
import ReactGA from 'react-ga4';

class ShopGroupImporter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonDataSet: [],
            filteredjsonDataSet: [],
            filteredjsonDataSet2: [],
            isLoading: true,
            isDataLoaded: false,
        };
    }

    componentDidMount() {
        fetch("https://link-router.netlify.app/damkoto/data/shopgroupimporter.json")
            .then(response => response.json())
            .then(responsejsonDataSet => {
                this.setState({ jsonDataSet: responsejsonDataSet });
                this.setState({ isLoading: false });
                this.setState({ isDataLoaded: true });
            });
    }

    render() {
        // open openInAppBrowser
        const openInAppBrowser = async (externalUrl,gacategory,gaaction) => {
            await window.open(externalUrl, '_blank');
            ReactGA.event({
                category: gacategory,
                action: gaaction
              });
        };
        // open openInAppBrowser
        const sortedData = this.state.jsonDataSet.sort((a, b) => parseInt(b.key_id) - parseInt(a.key_id));


        return (
            <>
            {this.state.isDataLoaded ?
                <div className="md:grid lg:grid-cols-4 lg:gap-4">
                {sortedData.map(individualjsonData => {
                            return (
                                <ShopsGroup secTitle={individualjsonData.secTitle} dataUrl={individualjsonData.dataUrl} key={individualjsonData.key_id} />
                            );
                          })}
                </div>:null}
            </>
        );
    }
}

export default ShopGroupImporter;