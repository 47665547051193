import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { Browser } from '@capacitor/browser';


class ShopsGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonDataSet: [],
            filteredjsonDataSet: [],
            filteredjsonDataSet2: [],
            isLoading: true,
            isDataLoaded: false,
        };
    }

    componentDidMount() {
        fetch(this.props.dataUrl)
            .then(response => response.json())
            .then(responsejsonDataSet => {
                this.setState({ jsonDataSet: responsejsonDataSet });
                this.setState({ isLoading: false });
                this.setState({ isDataLoaded: true });
            });
    }

    render() {
        // open openInAppBrowser
const openInAppBrowser = async (externalUrl,gacategory, gaaction) => {
    await Browser.open({
        url: externalUrl,
        windowName: 'Dam koto App',
        toolbarColor: '#ffffff',
    });
    ReactGA.event({
      category: gacategory,
      action: gaaction
    });
  };
  // open openInAppBrowser

        const sortedData = this.state.jsonDataSet.sort((a, b) => parseInt(b.key_id) - parseInt(a.key_id));


        return (
            <>
                {this.state.isDataLoaded ?
                    <div className='service-group rounded-lg bg-white dark:bg-slate-800 mt-3 shadow-lg'>
                        <div className="section-title bg-white dark:bg-slate-800 rounded-t-lg p-2 pl-5 text-gray-800 dark:text-white shadow-sm text-center text-lg">{this.props.secTitle}</div>
                        <div className="service-container grid gap-4 grid-cols-3 text-center py-3">
                            {sortedData.map(individualjsonData => {
                                return (
                                    
                                        
                                        <div className="service-item" key={individualjsonData.key_id} onClick={() => openInAppBrowser(individualjsonData.service_link, "Clicked services =", individualjsonData.service_name)}>
                                            <div className="service-item-img flex-col">
                                                <img src={individualjsonData.service_image} alt={individualjsonData.service_name} width={"60px"} className="inline border border-gray-400 rounded-xl" />
                                                <p className='dark:text-white text-sm'>{individualjsonData.service_name}</p>
                                            </div>
                                        </div>
                                    

                                );
                            })}
                        </div>
                    </div>


                    : null}
            </>
        );
    }
}

export default ShopsGroup;