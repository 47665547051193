import React from 'react';
import { PushNotifications } from '@capacitor/push-notifications';

const PushNotification = () => {
    PushNotifications.register(); 
    PushNotifications.addListener('registration', token => {
      console.info('DEBUG: Registration token: ', token.value);
    });
     PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('DEBUG:Push notification received: ', notification);
      });
  PushNotifications.addListener('registrationError', err => {
        console.error('DEBUG:Registration error: ', err.error);
      });
    return ( <></> );
}
 
export default PushNotification;