import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';

import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  //BannerAdPluginEvents,
  //AdMobBannerSize,
  RewardAdOptions,
  AdLoadInfo,
  RewardAdPluginEvents,
  AdMobRewardItem
} from "@capacitor-community/admob";

const ActionBar = () => {
  const [rewardVideoLoaded, setrewardVideoLoaded] = useState(false);

  
  // admob banner
  AdMob.initialize({
    testingDevices: ['962C3E1AA0A25ED70A48C40115BCBBCC','EB0058B0AB7574A8520695466CA62A13'],
    initializeForTesting: true,
  });
  //AdMob.addListener(BannerAdPluginEvents.Loaded, () => { // Subscribe Banner Event Listener });
  //AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => { // Subscribe Change Banner Size });
  const options: BannerAdOptions = {
    // adId: 'ca-app-pub-3940256099942544/6300978111',
    adId: "ca-app-pub-7756919313319706/8479111671",
    adSize: BannerAdSize.ADAPTIVE_BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0
  };
  AdMob.showBanner(options);
  // admob banner

  // admob reward video
  useEffect(() => {
    const options: RewardAdOptions = {
      adId: "ca-app-pub-7756919313319706/5336072648"
      //adId: "ca-app-pub-3940256099942544/5224354917" //demo ad ID
    };
    AdMob.prepareRewardVideoAd(options);
    AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      // Subscribe prepared rewardVideo
      console.log("DEBUG: reward Ad prepared");
      setrewardVideoLoaded(true);
    });
  }, []);

  const rewardVideo = async () => {
    AdMob.addListener(
      RewardAdPluginEvents.Rewarded,
      (rewardItem: AdMobRewardItem) => {
        // Subscribe user rewarded
        console.log("DEBUG: reward Ad shown and user rewarded");
        ReactGA.event({
          category: "Ad Revenue",
          action: "Reward Video Shown",
        });
        setrewardVideoLoaded(false);
      }
    );
    const rewardItem = await AdMob.showRewardVideoAd();
    ReactGA.event({
      category: "Ad Revenue",
      action: "Support Us Clicked"
    });
  };
  // admob reward video

  return (
    <>
      <div className="action-bar bg-white dark:bg-slate-800 p-5 sticky top-0 shadow-md">
        <div className="container mx-auto flex items-center">
        <div className="text-lg font-semibold text-gray-700 dark:text-white flex-auto">Dam koto</div>
          {rewardVideoLoaded ? (
            <div
              className="text-md font-light text-white bg-emerald-800 flex-none py-1 px-4 rounded-md" 
              onClick={rewardVideo}
            >
              Support Us
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ActionBar;