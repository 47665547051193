import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import { StatusBar, Style } from '@capacitor/status-bar';
import Home from './views/Home';

function App() {
  StatusBar.setStyle({ style: Style.Light });
  StatusBar.setBackgroundColor({ color: '#ffffff' });
  return (
<Router>
    <div className="App bg-slate-100 dark:bg-slate-900 pb-24 h-full">
      <Home/>
    </div>
    </Router>
  );
}

export default App;
